@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Saira Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121212;
  background-color: #fff;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: #1a3058;
  font-size: 1em;
  font-weight: 800;
  text-decoration: underline;
  text-underline-offset: 3px;
}

input,
select {
  width: 100%;
  height: 39px;
  font-family: 'Saira Condensed', sans-serif;
  border: 1px solid #ccc;
  color: #1a3058;
  font-size: 1em;
  padding: 5px 15px !important;
}

input {
  box-sizing: border-box;
}

input[type='checkbox'] {
  width: 18px;
  height: 18px;
}

.link-button {
  font-family: 'Saira Condensed', sans-serif;
  font-size: 18px;
  color: #263f6a;
  font-weight: 700;
  padding: 0px;
  background: none;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.field-style {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 24px 0px;
}

.formik-error {
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  color: rgb(239 68 68);
}

textarea {
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  font-family: 'Saira Condensed', sans-serif;
  border: 1px solid #1a3058;
  color: #1a3058;
  font-size: 1em;
  border-radius: 10px;
  padding: 5px 15px !important;
  min-height: 70px;
  max-height: 300px;
}

input:focus,
select:focus,
textarea:focus {
  outline: none !important;
  border: 1px solid #1591bf;
  box-shadow: 0 0 2px 2px rgb(93, 196, 255);
}

textarea:focus {
  border-radius: 20px 20px 4px 20px;
}

h1,
h2,
h3,
h4,
p {
  color: #263f6a;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
  font-weight: 700;
}

p.error {
  font-size: 1em;
  margin: 0px;
  margin-top: 10px;
  color: #d9143a;
}

b {
  color: #263f6a;
}

li {
  margin: 8px 0px;
  margin-left: 10px;
}

th,
td {
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  vertical-align: top;
}

.Toastify__toast {
  padding: 10px 10px 10px 15px !important;
  border-radius: 4px !important;
}

.regular-checkbox {
  margin-right: 10px;
}

/* 
 * Utility class to hide content visually while keeping it screen reader-accessible.
 * Source: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html 
 */
.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
